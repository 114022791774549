<template>
  <v-app-bar fixed class="jw-app-header">
    <template v-slot:prepend>
      <v-btn v-if="appHeader.btnBack" variant="text" icon="jwIcons:svg/arrow-left" size="24" :ripple="false" @click="onClickBack"></v-btn>
    </template>

    <!-- <v-app-bar-title v-if="appHeader.pageAuthor" class="text-center pr-12">
      <JwAuthor v-bind="appHeader" size="28" /> -->
    <v-app-bar-title v-if="appHeader.pageAuthor" class="text-center justify-center pr-12">
      <!-- <JwAuthor v-bind="appHeader.author" size="28" /> -->
      <JwAuthor v-bind="appHeader.author" :isHeader="true" size="28" :showLike="false" />
    </v-app-bar-title>
    <!-- <v-app-bar-title v-else :class="{ 'text-center': isTabletUp }" class="pl-2">{{ appHeader.pageTitle || "" }}</v-app-bar-title> -->
    <v-app-bar-title
      v-else
      :class="{ 'text-center': appHeader.btnBack, 'pr-8': !appHeader.btnClose, 'sub-title': route.name !== 'Today' }"
      class="pl-2"
      ><!-- {{ $t(titleKeyMatcher(appHeader.pageTitle)) || "" }} -->{{ appHeader.pageTitle }}</v-app-bar-title
    >
    <v-spacer v-if="!isTabletUp"></v-spacer>

    <v-btn rounded variant="flat" color="primary" class="mr-6" v-if="columnWriteBtn" @click.stop="toColumnWrite">쓰기</v-btn>
    <v-btn
      rounded
      variant="flat"
      color="primary"
      class="mr-6"
      v-if="postingWriteBtn && postingStore.state?.auth?.grpMngrYn == 'Y'"
      @click.stop="toPostingWrite"
      >쓰기</v-btn
    >
    <!-- <v-btn
      rounded
      variant="flat"
      color="primary"
      class="mr-6"
      v-if="isDev() && postingWriteBtn && postingStore.state?.auth?.grpMngrYn == 'Y'"
      @click.stop="toPostingWrite"
      >쓰기</v-btn
    > -->

    <!-- 투데이 위치조정 버튼 -->
    <v-btn data-text="투데이" v-if="route.name === 'Today' && loginStatus == true" variant="text" icon @click="todayStore.setOrderModal(true)">
      <v-icon>jwIcons:svg/sliders</v-icon>
    </v-btn>
    <v-btn v-if="appHeader.btnClose" variant="text" icon @click="onClickClose">
      <v-icon>jwIcons:svg/close</v-icon>
    </v-btn>
  </v-app-bar>

  <today-adj-modal />
</template>

<script setup>
import JwAuthor from "@/components/common/JwAuthor.vue";
import TodayAdjModal from "@/components/today/modal/TodayAdjModal.vue";

import { useDisplay } from "vuetify";
import { useCommonStore } from "~/store/common";
import { useTodayStore } from "~/store/today";
import { useAuthStore } from "~/store/auth";
import { useDiaryStore } from "~/store/diary";
import { usePostingStore } from "~/store/posting";
import { isDev } from "~/utils/utils";

const { xlAndUp, lgAndUp } = useDisplay();
//const isPc = computed(() => xlAndUp.value);
const isTabletUp = computed(() => lgAndUp.value);

defineProps({});
const store = useCommonStore();
const todayStore = useTodayStore();
const authStore = useAuthStore();
const diaryStore = useDiaryStore();
const postingStore = usePostingStore();

//const { appHeader: storeHeader = {} } = storeToRefs(store);
const storeHeader = computed(() => store.appHeader);

watch(
  () => storeHeader.value,
  () => {
    //console.log("storeHeader changed", storeHeader.value);
  },
  { deep: true, immediate: true }
);

const loginStatus = computed(() => authStore.loginStatus);
//const { columnDet, diaryDet } = storeToRefs(todayStore);
const columnDet = computed(() => todayStore.state.columnDet);
const diaryDet = computed(() => todayStore.state.diaryDet);
const route = useRoute();
const router = useRouter();

const columnWriteBtn = computed(() => {
  if (route.path === "/column" && todayStore?.state?.columnAuth?.essayAuthCd) {
    return true;
  }
  return false;
});
const postingWriteBtn = computed(() => {
  if (route.name == "groupPosting-grpCd") return true;
  return false;
});

const appHeader = computed(() => {
  console.log("in appHeader route ===> ", route);
  //if (route.params.section === "column") return { ...storeHeader.value };
  if (route.params.postingCd) {
    return {
      pageAuthor: true,
      btnBack: true,
      author: { ...postingStore.state.detail, imgSrc: `https://jwj.kr/upload/member/${postingStore.state.detail.profilePhoto}` },
    };
  }
  if (route.params.section === "column") {
    return store.appHeader;
  } else if (route.params.section === "rcmd" || route.name === "shareDetail" || route.name == "diary-diaryCd") {
    //let obj = { ...storeHeader.value, ...diaryDet.value };
    let obj = { ...diaryDet.value, ...storeHeader.value };
    obj.author.emotion = diaryDet.value.weather || "";
    return obj;
  } else if (route.name == "bookmark-view-bMCd") {
    return { ...diaryStore.state.bookMarkInfo, btnBack: true };
  }

  return storeHeader.value;
});
console.log("appHeader", appHeader.value);

/* store.$subscribe((mutation, state) => {
  //appHeader = { ...state.appHeader };
  storeHeader = { ...state.appHeader };
}); */

function onClickBack() {
  if (route.name == "groupPosting-grpCd") {
    return router.push(`/today`);
  }
  // 우선 임시로 라우터 히스토리백으로 했습니다.
  router.back();
}
function onClickClose() {
  // 우선 임시로 라우터 히스토리백으로 했습니다.
  router.back();
}

function titleKeyMatcher(title) {
  const match = {
    투데이: "dwj.home.001",
  };
  return match[title] || title;
}

function toColumnWrite() {
  router.push("/column/write");
}
function toPostingWrite() {
  router.push(`/groupPosting/${route.params.grpCd}/write`);
}
</script>
